.ion-modal {
    --backdrop-opacity: .3;
    --border-radius: 26px;
    --background: var(--color-violet-500);
    --ion-toolbar-background: var(--color-violet-500);
    --ion-item-background: transparent;
    --ion-padding: 24px;
    --max-width: 600px;

    --border-width: 0;

    @media only screen and (max-width: 768px) {
        --max-height: 95%;
        --border-radius: 26px 26px 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .modal-title {
        color: var(--color-green-300);
        text-align: center;
        font-weight: 700;
        padding-left: 20px;
        padding-right: 20px;
    }

    .modal-title-descr {
        text-align: center;
        margin-bottom: 38px;
        display: block;
        color: var(--color-neutral-200);
    }

    ion-grid {
        --ion-grid-padding: 0;
        --ion-grid-column-padding: 10px;
    }

    ion-content {
        --background: var(--color-violet-500);
    }

    ion-toolbar {
        --padding-start: 24px;
        --padding-end: 24px;
        --padding-bottom: 24px;
        --padding-top: 24px;
        --border-width: 0 !important;
    }

    ion-header {
        ion-toolbar {
            --padding-bottom: 0;
        }
    }

    .btn-group {
        margin-top: 0;

        ion-button {
            margin: 0;
        }

        .cancel-btn {
            margin-top: 10px;
        }
    }
}

.time-select--modal {
    //--height: 50%;
    //--width: 100%;
    --max-width: 320px;
    --backdrop-opacity: .3;
    --border-radius: 26px;
    //--background: var(--color-gradient-400-2);
    //--ion-toolbar-background: var(--color-gradient-400-2);
    --background: var(--color-neutral-700);
    --ion-toolbar-background: var(--color-neutral-700);
    --ion-item-background: transparent;
    --ion-padding: 24px;

    .modal-title {
        color: var(--color-green-300);
        text-align: center;
        font-weight: 700;
        margin-bottom: 20px;
    }

    .modal-title-descr {
        text-align: center;
        margin-bottom: 38px;
        display: block;
        color: var(--color-neutral-200);
    }

    .modal--theme {
        padding: 28px 10px;
    }

    ion-grid {
        --ion-grid-padding: 0;
        --ion-grid-column-padding: 10px;
    }

    ion-content {
        --background: var(--color-violet-900);
    }

    .datetime-presentation-date,
    .datetime-presentation-time,
    .datetime-presentation-year {

        //--ion-color-contrast: var(--color-gradient-400-2) !important;
        //--background-rgb: 19, 8, 43 !important;
        //--background: var(--color-gradient-400-2) !important;

        --ion-color-contrast: var(--color-neutral-700) !important;
        --background-rgb: 83, 72, 109 !important;
        --background: var(--color-neutral-700) !important;


    }

    ion-toolbar {
        --padding-start: 24px;
        --padding-end: 24px;
        --padding-bottom: 24px;
        --padding-top: 24px;
        --border-width: 0 !important;
    }

    ion-header {
        ion-toolbar {
            --padding-bottom: 0;
        }
    }

    .button {
        width: 50%;
        display: flex;
        margin: 20px auto 0 auto;
        min-width: 150px;
    }
}
