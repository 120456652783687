.sc-ion-alert-md-h,
.sc-ion-alert-ios-h {
    --background: var(--default-white);
    --ion-text-color: var(--color-neutral-400);
    --ion-item-background: var(--default-white);
    --ion-item-color: var(--color-neutral-400);
    --ion-item-border-color: var(--color-neutral-100);
    //--ion-color-step-850: var(--color-neutral-400);
    .alert-title.sc-ion-alert-md {
        font-size: 16px;
    }

    .alert-head {
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        padding-top: 16px;
        padding-bottom: 16px;
    }
}

.alert-checkbox {
    .alert-checkbox-icon {
        border-color: var(--color-neutral-300) !important;
        background-color: var(--default-white) !important;
    }
}
