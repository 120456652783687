ion-progress-bar {
    --height: 8px;
    --radius: 50px;
    --background: var(--color-violet-200);
    --progress-background: var(--color-gradient-200);
    --stream-color: var(--color-gradient-200-1);

    height: var(--height);
    width: var(--width);
    border-radius: var(--radius);

    &::part(stream) {
        background-image: radial-gradient(ellipse at center, var(--stream-color) 0%, var(--stream-color) 30%, transparent 30%);
    }
}
