
.tree {
    @apply relative pt-[10px];
    overflow: hidden;

    &:after {
        @apply content-[''] absolute bg-white bottom-0 w-[6px] h-[23px];
    }
}

.mat-tree-node {
    @apply relative;

    &[aria-expanded="true"] {
        .mat-mdc-button-base {
            @apply text-blue-200;
            z-index: 1;
        }

        .has-sub {
            &:before {
                @apply content-[''] absolute border-gray-300 border-l left-[19px] top-[28px] w-[1px] h-[40px];
            }
        }
    }

    &[aria-level="1"] {
        .has-sub {
            &:before {
                @apply content-[''] absolute border-gray-300 border-l left-0 top-[-27px] w-[1px] h-[5000px];
            }
        }
    }

    &:last-child {
        &[aria-level="1"] {
            .has-sub {
                &:before {
                    @apply hidden;
                }
            }
        }
    }

    &:not([aria-level="1"]) {
        .has-sub {
            &:after {
                @apply content-[''] absolute border-gray-300 border-t left-[-17px] top-1/2 w-[30px] h-[1px];
            }

            &:before {
                @apply content-[''] absolute border-gray-300 border-l left-[-17px] top-[-15px] w-[1px] h-[40px];
            }
        }

        .child-item {
            &:after {
                @apply content-[''] absolute border-gray-300 border-t left-[-17px] top-1/2 w-[68px] h-[1px];
            }

            &:before {
                @apply content-[''] absolute border-gray-300 border-l left-[-17px] top-[-24px] w-[1px] h-[48px];
            }
        }
    }

    .mat-mdc-radio-button {
        margin: 0;
    }

    .child-item {
        &:after {
            @apply content-[''] absolute border-gray-300 border-t left-0 top-1/2 w-[50px] h-[1px];
        }
    }

    .has-sub {
        &:after {
            @apply content-[''] absolute border-gray-300 border-t left-0 top-1/2 w-[10px] h-[1px];
        }

        &:before {
            @apply content-[''] absolute border-gray-300 border-l left-0 top-[-27px] w-[1px] h-[96px];
        }
    }
}
