ion-toast {
    &.ypa-toast {
        position: fixed;
    }
    &.ypa-toast--error {
        position: fixed;
    }
    &.ypa-toast--success {
        position: fixed;
    }
}
