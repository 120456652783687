ion-badge {
    --background: var(--color-highlight-300);
    --color: var(--default-white);
    --padding-top: 2px;
    --padding-bottom: 2px;
    --padding-end: 2px;
    --padding-start: 2px;
    --border: none;
    --size: 20px;
    font-weight: 700;
    font-size: 10px;
    width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
