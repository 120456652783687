ion-checkbox {
    --size: 20px;
    --border-width: 2px;
    --padding-end: 10px;
    --background: transparent;
    --checkmark-color: var(--default-white);
    --checkmark-width: 2px;
    --border-color-checked: var(--color-blue-300);
    margin-inline-end: 15px;
}
