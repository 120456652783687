.modal-language {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 24px 24px 24px;
    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
    }

    .mdc-dialog__title {
        height: auto;
        padding: 0;
        margin: 0;

        &:before {
            height: auto;
        }
    }
}
