ion-radio {
    --color-checked: var(--color-blue-300);
    --color: var(--default-white);
}


//custom radio in div
//!! CLICK WIILL WORK ONLY ON RADIO
//<div class="radio-custom">
//<ion-radio value="pineapple"></ion-radio>
//<ion-label>Pineapple</ion-label>
//</div>

.radio-custom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    ion-label {
        margin-left: 18px;
    }
}
.select-interface-option.md.alert-radio-button {
    .alert-radio-icon {
        display: flex;
        justify-content: center;
        align-items: center;

        .alert-radio-inner {
            position: relative;
            top: initial;
            inset-inline-start: initial;
        }
    }
}
