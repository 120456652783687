ion-popover {
    --background: var(--default-white);
    --ion-item-background: var(--default-white);
    --ion-item-color: var(--color-neutral-400);
    --ion-item-border-color: var(--color-neutral-100);
    --backdrop-opacity: 0.3;
}

.select-popover {
    --background: var(--default-white);
    --ion-item-background: var(--default-white);
    --ion-item-color: var(--color-neutral-400);
    --ion-item-border-color: var(--color-neutral-100);
    --backdrop-opacity: 0.3;

    ion-checkbox {
        --border-width: 1px;
        --background-checked: var(--default-white);
        --border-color-checked: var(--color-neutral-400);
        --checkmark-color: var(--color-neutral-300);
    }
}

.sc-ion-select-popover-ios,
.sc-ion-select-popover-md {
    --padding-start: 16px;
    --inner-padding-end: 16px;
}
