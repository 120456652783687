// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/


//
///** Ionic CSS Variables **/
:root {
    --ion-color-primary: var(--color-blue-300);
    --ion-color-primary-rgb: 34, 31, 201;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: var(--color-blue-400);
    --ion-color-primary-tint: var(--color-blue-200);

    --ion-color-secondary: var(--color-green-300);
    --ion-color-secondary-rgb: 42, 248, 209;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: var(--color-green-400);
    --ion-color-secondary-tint: var(--color-green-200);

    --ion-color-tertiary: var(--color-violet-300);
    --ion-color-tertiary-rgb: 60, 18, 154;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: var(--color-violet-400);
    --ion-color-tertiary-tint: var(--color-violet-200);

    --ion-color-success: var(--color-green-300);
    --ion-color-success-rgb: 42, 248, 209;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: var(--color-green-400);
    --ion-color-success-tint: var(--color-green-200);

    --ion-color-warning: var(--color-highlight-100);
    --ion-color-warning-rgb: 229, 174, 26;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: var(--color-highlight400);
    --ion-color-warning-tint: var(--color-highlight-200);

    --ion-color-danger: var(--color-warning-100);
    --ion-color-danger-rgb: 233, 34, 94;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cd1e53;
    --ion-color-danger-tint: #eb386e;

    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    --ion-color-step-50: #f2f2f2;
    --ion-color-step-100: #e6e6e6;
    --ion-color-step-150: #d9d9d9;
    --ion-color-step-200: #cccccc;
    --ion-color-step-250: #bfbfbf;
    --ion-color-step-300: #b3b3b3;
    --ion-color-step-350: #a6a6a6;
    --ion-color-step-400: #999999;
    --ion-color-step-450: #8c8c8c;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #737373;
    --ion-color-step-600: #666666;
    --ion-color-step-650: #595959;
    --ion-color-step-700: #4d4d4d;
    --ion-color-step-750: #404040;
    --ion-color-step-800: #333333;
    --ion-color-step-850: #262626;
    --ion-color-step-900: #191919;
    --ion-color-step-950: #0d0d0d;

}

/* MAIN IONIC COLORS THEME*/
:root {
    --ion-background-color: var(--color-neutral-900);
    --ion-background-color-rgb: 19, 8, 43, 1;
    --ion-text-color: var(--default-white);
    --ion-text-color-rgb: 255, 255, 255, 1;
    --ion-backdrop-color: #000000;
    --ion-backdrop-opacity: .7;
    --ion-overlay-background-color: 27, 0, 88, .7;
    --ion-border-color: var(--color-neutral-700);
    --ion-item-border-color: transparent;
    --ion-item-color: var(--default-white);
    --ion-placeholder-color: var(--color-blue-400);
    --ion-font-family: var(--font-family);

    --ion-grid-padding:16px;
    --ion-grid-padding-sm:24px;

}
