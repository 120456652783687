.text-editor {
    .ap {
        margin: 5px;
    }
    .ql-emojiblot{
     margin-top: -7px;
    }
}

#emoji-palette #tab-panel {
    max-height: 155px !important;
}
