ion-button {
    --border-radius: 80px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --padding-start: 20px;
    --padding-end: var(--padding-start);
    --height: 50px;
    --font-weight: 700;
    --font-size: 16px;
    --line-height: 1.1875;
    --box-shadow: none;
    min-width: 200px;

    font-weight: var(--font-weight);
    text-transform: none;
    font-size: var(--font-size);
    line-height: var(--line-height);
    height: var(--height);

    &.button-disabled {
        --ion-color-base: var(--color-neutral-200) !important;
        --background: var(--color-neutral-200) !important;
        --color: var(--color-neutral-300) !important;
        --ion-color-contrast: var(--color-neutral-300) !important;
        --opacity: 1;
        opacity: 1;
    }

    &.button-small {
        --padding-top: 6px;
        --padding-bottom: 6px;
        --padding-start: 12px;
        --height: 32px;
        --font-weight: 600;
        --font-size: 13px;
        min-width: 80px;
    }

    &.button-default {
        --padding-start: 50px;
    }

    &.button-has-icon-only {
        --padding-start: var(--padding-top);
    }

    &.button-clear{
        --padding-top: 5px;
        --padding-bottom: 5px;
        --height: auto;
    }

    ion-icon{
        flex-shrink: 0;
        font-size: 28px;
    }
}

.skip-btn, .prev-btn {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-neutral-600);
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    transition: color .5s ease;
    line-height: 1.2;
    padding: 0 0 5px 0;

    .fa {
        margin-left: 8px;
        font-size: 10px;
    }

    @media (hover: hover) {
        &:hover {
            color: var(--default-white);
        }
    }

    &[disabed],
    &[disabled=true] {
        pointer-events: none;
        cursor: default;
        opacity: .5;
    }
}

.prev-btn {
    flex-direction: row-reverse;

    .fa {
        margin-right: 8px;
    }
}
