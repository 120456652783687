
.sc-ion-input-ios-h,
.sc-ion-input-md-h {
    --background: var(--default-white);
    --color: var(--color-blue-400);
    --border-radius: 12px;
    --box-shadow: inset 0px 3px 8px rgba(0, 0, 0, 0.15);
    --border-color: transparent;
    --border-width: 0;

    --font-size: 14px;
    --font-weight: 600;
    --line-height: 1.5;
    --text-align: left;

    --padding-bottom: 15px;
    --padding-top: 15px;
    --padding-end: 10px;
    --padding-start: 10px !important;

    .input-bottom {
        --highlight-color-invalid: var(--ion-color-danger) !important;
    }

    .input-highlight {
        //background: transparent !important;
        margin-left: 10px;
        height: 1px !important;
        width: calc(100% - 20px);
        bottom: 0 !important;
    }

    &.input-disabled {
        opacity: .8;
        --color: var(--color-blue-400);
    }
}

.sc-ion-input-md-h {
    min-height: 50px !important;
}

.input-item {
    margin-bottom: 20px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    ion-note {
        margin-left: 6px;
        margin-bottom: 3px;
        font-size: 12px;
        &:first-child {
            margin-top: 5px;
        }
    }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
