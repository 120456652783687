.mat-mdc-tab-group {
    .mat-mdc-tab-label-container {
        .mat-mdc-tab-list {
            border-bottom: 1px solid rgba(0, 0, 0, .12);

            .mat-mdc-tab {
                min-width: 50px;
                height: 40px;
                opacity: 1;
                margin-right: 20px;
                padding: 0 10px;
                .mdc-tab__text-label {
                    @apply font-semibold;
                }
            }
        }
    }
}

