/* Set the width to the full container and center the content */
ion-select {
    --background: var(--default-white);
    --radius: 12px;
    --border-radius: var(--radius);
    --border-color: transparent;
    --border-width: 0;

    --font-size: 14px;
    --font-weight: 600;
    --line-height: 1.5;
    --text-align: left;

    --padding-bottom: 15px;
    --padding-top: 15px;
    --padding-end: 10px;
    --padding-start: 10px;

    border-radius: var(--radius);
    --placeholder-color: var(--color-blue-400);
    --color: var(--color-blue-400);
    --highlight-color: transparent;
    --highlight-color-valid:transparent;

    color: var(--color);

    min-height: 50px;

    //
    //--padding-bottom: 14px !important;
    //--padding-top: 14px !important;
    //--padding-end: 15px !important;
    //--padding-start: 10px !important;
    //
    //--font-size: 14px;
    //--font-weight: 600;
    //--line-height: 1.5;
    //--text-align: left;
    //
    //justify-content: center;
    //height: 50px;
    //font-weight: var(--font-weight);
    //font-size: var(--font-size) !important;
    //line-height: var(--line-height);
    //text-align: var(--text-align);
    //padding: var(--padding-top) var(--padding-end) var(--padding-bottom) var(--padding-start);
}

/* Set the placeholder color and opacity */
ion-select::part(placeholder) {
    color: var(--color);
    opacity: 1;
}

/* Set the text color */
ion-select::part(text) {
    color: var(--color);
}

/* Set the icon color and opacity */
ion-select::part(icon) {
    color: var(--color);
    opacity: 1;
}
