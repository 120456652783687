
.el-group {
    .mat-mdc-form-field-hint {
        @apply block text-sm font-medium text-gray-700;
    }
}

.mat-field {
    width: 100%;
    margin-top: 6px;
}

.no-hint {
    .input-field, .textarea-field, .select-field, .date-field, .autocomplete-chip-field {
        .mat-mdc-form-field-bottom-align {
            min-height: 0;
        }
    }
}

.input-field, .textarea-field, .select-field, .date-field, .autocomplete-chip-field {
    width: 100%;
    font-size: 14px;
    --mdc-shape-small: 5px;
    --mdc-typography-body-font-size: 14px;

    &.mat-focused {
        .mat-mdc-text-field-wrapper {
            .mdc-notched-outline {
                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: var(--color-blue-300);
                }
            }
        }
    }

    &.no-hint {
        .mat-mdc-form-field-bottom-align {
            min-height: 0;
        }
    }

    &.mat-form-field-invalid {
        .mat-mdc-text-field-wrapper {
            .mdc-notched-outline {
                .mdc-notched-outline__leading,
                .mdc-notched-outline__notch,
                .mdc-notched-outline__trailing {
                    border-color: var(--color-warning-100);
                }
            }
        }
    }

    .mat-mdc-text-field-wrapper {
        padding: 0 .75em 0 .75em;
        background: var(--default-white);
        position: relative;
        --mat-mdc-form-field-label-offset-x: -12px !important;
        @apply shadow-sm;

        .mdc-notched-outline__notch {
            border-right: none !important;
            --mat-mdc-form-field-floating-label-scale: .75;
        }


        .mdc-notched-outline {
            .mdc-notched-outline__leading,
            .mdc-notched-outline__notch,
            .mdc-notched-outline__trailing {
                border-color: rgba(0, 0, 0, .12);
            }

            &.mdc-notched-outline--notched {
                .mdc-notched-outline__notch {
                    .mdc-floating-label {
                        font-size: 15px;
                        left: 3px;
                    }

                    .mat-mdc-form-field-required-marker {
                        display: none;
                    }
                }
            }
        }

        .mat-mdc-form-field-infix {
            min-height: 38px;
            padding: 7px 0;
        }

        .mat-mdc-form-field-flex {
            .mat-mdc-floating-label {
                top: 50%;
                left: 6px;
            }

            .mdc-floating-label--float-above {
                top: 78%;
            }
        }

        .mat-mdc-input-element {
            &:focus {
                border: none;
                outline: none;
                box-shadow: none;
            }
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        &:before {
            display: none;
        }
    }

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
        padding: 0 9px;
        position: relative;
    }

    .mat-mdc-form-field-bottom-align {
        min-height: 20px;

        &:before {
            display: none;
        }
    }

    .mat-mdc-form-field-icon-prefix, [dir="rtl"] .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-suffix, [dir="rtl"] .mat-mdc-form-field-icon-prefix {
        padding: 0 10px 0 0;

        .prefix {
            padding: 0 0 0 10px;

            &:empty {
                display: none;
            }
        }
    }

}

.input-field {
}

.textarea-field {
    .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
            .mat-mdc-floating-label {
                top: 18%;
            }
        }
    }

    .mat-mdc-input-element {
        min-height: 150px;
        resize: none;
    }
}

.select-field {
    [multiple] {
        border: none;
        padding: 0;
    }
}

.autocomplete-chip-field {
    &.mat-form-field-appearance-outline {
        /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
        .mat-form-field-infix {
            //padding: 10px 0 2px 0 !important;
        }

        /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
        .mat-chip-list {
            display: none;

            &[role="listbox"] {
                display: block;
                margin-bottom: 5px;
            }
        }

        input.mat-mdc-chip-input {
            margin: 0;
        }
    }
}

.mat-mdc-radio-group {
    .mat-mdc-radio-button {
        margin: 0 12px;
    }
}

.mat-mdc-chip {
    &.mat-mdc-standard-chip {
        /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
        .mat-chip-remove {
            @apply text-red-500 opacity-100;
        }
    }
}

.date-field {
    /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
    .mat-mdc-form-field-wrapper {
        margin: 0;


    }

    .mat-mdc-form-field-icon-suffix {
        padding: 0 !important;
    }

    .mat-mdc-icon-button.mat-mdc-button-base {
        width: 36px;
        height: 36px;
        padding: 7px;
    }

    .mat-mdc-form-field-icon-prefix > .mat-icon, .mat-mdc-form-field-icon-suffix > .mat-icon {
        padding: 7px;
    }

    .mat-datepicker-toggle {
        display: inline-block;

        .mat-mdc-icon-button {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

button:disabled,
button[disabled] {
    opacity: .5;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
    &:focus {
        --tw-ring-color: transparent;
    }
}


.chip-checkbox {
    @apply pr-4 rounded-3xl;
    --background: #fafafa;
    --color: var(--color-neutral-800);
    background: var(--background);
    --mdc-theme-text-primary-on-background: var(--background);
    --mdc-checkbox-unselected-icon-color: var(--color-neutral-200);
    --mdc-checkbox-unselected-hover-icon-color: var(--color-gradient-200-1);
    --mdc-checkbox-unselected-hover-state-layer-color: var(--color-gradient-200-1);
    --mdc-checkbox-selected-hover-icon-color: transparent;
    --mdc-checkbox-selected-pressed-icon-color: transparent;
    --mdc-checkbox-selected-icon-color: rgba(255, 255, 255, .1);
    --mdc-checkbox-selected-focus-state-layer-color: var(--color-gradient-200-1);
    --mdc-checkbox-selected-pressed-state-layer-color: var(--color-gradient-200-1);
    --mdc-checkbox-selected-focus-icon-color: var(--color-gradient-200-1);
    transition: all .3s ease;

    &.check-all {
        --mdc-checkbox-unselected-icon-color: var(--color-neutral-800);
        --mdc-checkbox-selected-icon-color: var(--color-neutral-800);
        --mdc-checkbox-selected-checkmark-color: var(--default-white);
        --mdc-checkbox-selected-focus-state-layer-color: var(--color-neutral-800);
        --mdc-checkbox-selected-pressed-state-layer-color: var(--color-neutral-800);
        --mdc-checkbox-selected-focus-icon-color: var(--color-neutral-800);

        .mdc-checkbox__background {
            background: var(--color-neutral-800) !important;
        }
    }

    .mdc-checkbox__background {
        border: 1px solid currentColor;
    }

    .mdc-label {
        color: var(--color);
        padding-left: 2px;
    }

    &.mat-mdc-checkbox-checked {
        --background: var(--color-highlight-500);
        --color: var(--default-white);

        &.check-all {
            --background: #fafafa;
            --color: var(--color-neutral-800);
        }
    }
}

.chip-checkbox-tooltip {
    top: 6px;
}

.select-language {
    margin: 0;
    width: 150px;
    --mdc-shape-small: 0;

    .mat-mdc-text-field-wrapper {
        padding: 0;
        border: none;
        box-shadow: none;

        .mdc-notched-outline {
            display: none;
        }
    }

    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }

    .mat-mdc-form-field-infix {
        width: 150px;
        padding: 0 !important;
        min-height: 0 !important;
    }

    .mat-mdc-select-value {
        color: #3f51b5;
        margin-right: 8px;
    }

    .mat-mdc-select {
        @apply border-b border-b-gray-300;
    }
}

