.ion-datetime-button-overlay {
    .ion-page {
        border-radius: 20px;
    }
}

ion-datetime {
    height: auto;
    width: 300px !important;
    max-width: 350px;
    margin: 0 auto;
    --background: initial;
    --ion-color-step-150: transparent;
    --backdrop-opacity: 1;
    --background-rgb: 255, 255, 255;

    .modal-wrapper {
        border-radius: 20px;
    }

}

//ionic 6 date time picker styles
.datetime-presentation-date,
.datetime-presentation-time,
.datetime-presentation-year {
    --title-color: var(--default-white) !important;
    --color: var(--default-white) !important;
    --ion-color-primary: var(--color-green-300) !important;
    --ion-color-base: var(--color-green-300) !important;
    --ion-color-base-rgb: 42, 248, 209 !important;
    --ion-color-contrast: var(--color-neutral-900) !important;
    --ion-color-step-650: var(--title-color) !important;
    --ion-color-step-500: var(--title-color) !important;
    --background-rgb: 19, 8, 43 !important;
    --background: var(--color-neutral-900) !important;
    color: var(--default-white);
    border-radius: 10px;
}

.datetime-presentation-date {
    &::part(calendar-day) {
        width: 36px !important;
        min-width: 36px !important;
        height: 36px !important;
        padding-inline: 3px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-inline: 3px;
        margin-top: 3px;
        margin-bottom: 3px;
    }

    &::part(calendar-day active) {
        color: var(--color-neutral-900) !important;
    }

    &.ios {
        &::part(calendar-day active) {
            color: var(--color-neutral-900) !important;
            background: var(--color-green-300) !important;
        }
    }
}

.datetime-presentation-year {
    &::part(wheel-item active) {
        color: var(--color-green-300);
    }
}

.mega-picker {
    position: relative;
    overflow: hidden;
    --wheel-fade-background-rgb: transparent;
    --wheel-highlight-background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;

    .legend-container {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: nowrap;
    }

    .legend {
        font-size: 10px;
        line-height: 50px;
        color: var(--color-neutral-600);
        width: 100px;
        text-align: center;
    }

    .time-picker-picker {
        position: relative;
        overflow: hidden;
    }

    .dots {
        position: absolute;
        pointer-events: none;
        width: 16px;
        top: -41px;
        left: 50%;
        transform: translateX(-9px);
        text-align: center;

        .dot {
            color: var(--color-neutral-600);
            font-weight: 200;

            &:after {
                content: ':';
                font-size: 52px;
                line-height: 1;
                height: 55px;
                display: inline-block;


            }

            &:nth-child(3) {
                font-weight: 300;
                color: var(--color-green-300);
            }
        }
    }

    ion-datetime {
        overflow: hidden;

        &::part(wheel-item) {
            font-size: 52px;
            line-height: 34px;
            height: 40px;
            letter-spacing: 1.04;
            margin: 15px 0;
            color: var(--color-neutral-800);
            font-weight: 200;
            text-align: center;
            position: relative;
            transition: all .3s ease;
            overflow: initial;
        }

        &::part(wheel-item active) {
            color: var(--color-green-300);
            //font-weight: 300;
        }

    }
}

.minmaxpicker {
    &::part(calendar-day today) {
        color: var(--default-white);
    }

    &::part(calendar-day):hover {
        background-color: rgb(154 209 98 / 0.2);
        box-shadow: 0px 0px 0px 4px rgb(154 209 98 / 0.2);
    }

    &::part(calendar-day active):hover {
        background: var(--color-green-300);
        color: var(--default-white);
    }
}

