.ypa-default-select {
    .select-interface-option {
        --color: #971e49;
        --color-hover: #79193b;
    }

    /* Alert Interface: set color for alert options (single selection) */
    .select-interface-option .alert-radio-label {
        color: #971e49;
    }

    /* Alert Interface: set color for alert options (multiple selection) */
    .select-interface-option .alert-checkbox-label {
        color: #971e49;
    }

    /* Alert Interface: set color for checked alert options (single selection) */
    .select-interface-option[aria-checked=true] .alert-radio-label {
        color: #79193b;
    }

    /* Alert Interface: set color for checked alert options (multiple selection) */
    .select-interface-option[aria-checked=true] .alert-checkbox-label {
        color: #79193b;
    }
}
