
.collector {
    display: flex;
    flex-direction: column;
    max-width: 480px;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;
}

.collector-head {
    margin: 0 24px 32px 24px;

    .collector-head--title {
        font-weight: 600;
        font-size: 26px;
        text-align: center;
        line-height: 1.2;
    }

    .collector-head--sub-title {
        font-weight: 500;
        font-size: 14px;
        margin-top: 12px;
        text-align: center;
        color: var(--color-neutral-600);
        line-height: 1.2;
    }
}

.collector-content {
    margin: 0 24px;
}

.collector-hint {
    background: rgba(55, 48, 75, 0.7);
    border-radius: 12px;
    padding: 14px 20px;
    color: var(--default-white);
    margin-top: 48px;

    .hint-title {
        font-weight: 700;
        font-size: 21px;
        line-height: 1.2;
        color: var(--color-green-300);
        margin-bottom: 14px;
    }

    p {
        &::ng-deep {
            a {
                color: var(--color-green-300);
            }
        }

        a {
            color: var(--color-green-300);
        }
    }
}
