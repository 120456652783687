.ypa-mat-table--container {
    width: calc(100% - 4px);
    margin: 2px;
    @apply overflow-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg;
}

.mat-mdc-paginator {

}

.ypa-mat-table {
    width: 100%;

    .mat-mdc-header-cell {
        min-width: 150px;
        box-sizing: border-box;

    }

    .mat-mdc-header-cell {
        @apply py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900;
        @apply bg-gray-50 #{!important};
    }

    .mat-mdc-row:nth-child(even) {
        @apply bg-gray-50;
    }

    .mat-mdc-row {
        &:hover {
            @apply bg-gray-50;
        }
    }

    .mat-mdc-cell {
        @apply px-3 py-4 text-sm text-gray-500;
    }

    .left {
        text-align: left;
    }

    .right {
        text-align: right;
    }

    .id {
        width: 50px;
        min-width: 50px;
    }

    .name {
        width: 20%;
    }

    .actions {
        width: 150px;
    }

    .is-enabled {
        width: 150px;
    }

    .isAdmin {
        width: 150px;
    }

    .tags {
        width: 20%;
    }

    .gender {
        width: 150px;
    }

    .description, .ignore-text {
        width: 300px;
    }

}

