ion-toast {
    &.ypa-toast {
    }

    &.ypa-toast--error {
        --background: var(--color-highlight-300);
        --color: var(--default-white);

        &::part(message) {

        }

        &::part(button) {

        }
    }

    &.ypa-toast--success {
        --background: var(--color-green-500);
        --color: var(--default-white);

        &::part(message) {

        }

        &::part(button) {

        }
    }

    &::part(message) {
        font-size: 14px;
        font-weight: 700;
    }

    &::part(button) {
        color: var(--default-white);
        font-size: 14px;
    }
}
