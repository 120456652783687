.mat-sidenav-fixed {
    min-width: 300px;
    padding: 30px 0 0 0;

    .link {
        padding: 0 30px;

        &.active {
            @apply bg-gray-100 text-gray-900;
        }
    }
}
