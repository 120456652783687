.main-calendar-holder {
    max-width: 480px;
    padding-top: calc(var(--ion-safe-area-top, 0));
    position: absolute;
    width: 100%;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    border-radius: 20px;
    background-color: var(--color-neutral-800); //#53486D;
    z-index: 3;
    top: 0;
    height: 2px;
    //transition: top .3s ease-out;

    .calendar-container {
        --background-color: var(--color-neutral-800);
        --color: var(--default-white);
        --week-title-color: #918D9A;
        --week-day-color: var(--week-title-color);
        --cell-today-bck: var(--default-white);
        --cell-today-color: var(--color-neutral-900);
        --cell-today-active-color: #372E4B;
        --cell-today-has-event: #FFDE32;
        --cell-today-sucess: var(--color-green-300);
        --cell-margin: 16px;
        background: var(--background-color);
        border-radius: 0 0 20px 20px;

        width: 100%;
        bottom: 80px;
        position: absolute;
        transition: transform .3s;

        .calendar-date-holder {
            background-color: var(--color-neutral-800);

            // strange minus margins are used for animation fix and artifact line fix
            margin-top: -50px;
            padding: 90px 0 25px;
            margin-bottom: -5px;
        }
    }

    .cal-month-view {
        background-color: var(--color-neutral-800); //#53486D;
        padding: 20px var(--cell-margin) calc(var(--cell-margin) / 2) var(--cell-margin);
        border-radius: 0 0 20px 20px;
        min-height: 290px;

        //calendar header
        .cal-header {
            display: flex;
            text-align: center;
            font-weight: 700;
            color: var(--week-title-color);
            font-size: 10px;
            width: calc(100% + var(--cell-margin));
            margin-left: calc(var(--cell-margin) / -2);

            &.cal-cell-row {
                &:hover {
                    background: initial;
                }

                .cal-cell {
                    padding: 0 calc(var(--cell-margin) / 2);

                    &:hover {
                        background: initial;
                    }
                }
            }

        }

        //calendar body
        .cal-days {
            border: none;

            .cal-cell-row {
                display: flex;
                grid-gap: var(--cell-margin);
                margin: calc(var(--cell-margin) / 2) 0;
                border: none;

                &:hover {
                    background: transparent;
                }
            }

            .cal-day-cell {
                min-height: 0;
                position: relative;
                text-align: center;
                border-radius: 50%;
                transition: all .5s ease;

                &:hover {
                    background-color: rgba(255, 255, 255, .05);

                    .cal-day-number {
                        color: var(--cell-today-color);
                    }
                }

                &:after {
                    padding: 0 0 100% 0;
                    content: '';
                    display: table;
                }

                &:not(:last-child) {
                    border: none;
                }

                &.cal-out-month {
                    opacity: .35;

                    .cal-day-number, .cal-day-badge {
                        opacity: 1;
                    }
                }

                &.cal-today {
                    background-color: var(--cell-today-bck);
                    color: var(--cell-today-active-color) !important;

                    .cal-day-number {
                        color: var(--cell-today-active-color);
                    }
                }

                &.cal-weekend {
                    .cal-day-number {
                        color: var(--week-day-color);
                    }
                }

                &.cal-has-events {
                    background: transparent;
                }

                .cal-cell-top {
                    min-height: auto;
                    position: absolute;
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.has-events {
                        border: 1px solid var(--cell-today-has-event);

                        &:after {
                            content: '!';
                            position: absolute;
                            width: 16px;
                            padding: 0;
                            top: -5px;
                            right: -3px;
                            height: 16px;
                            display: flex !important;
                            align-items: center;
                            justify-content: center;
                            font-size: 10px;
                            font-weight: 800;
                            background: var(--cell-today-has-event);
                            border-radius: 50%;
                        }

                        .cal-day-number {
                            color: var(--cell-today-has-event);
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            border-radius: 50%;
                            width: 100%;
                            height: 100%;
                            background-color: var(--cell-today-has-event);
                            opacity: 0;
                            transition: all .5s ease;
                        }

                        &:hover {
                            &::before {
                                opacity: .05;
                            }
                        }
                    }

                    &.success-events {
                        --cell-today-has-event: var(--cell-today-sucess);

                        &:after {
                            content: '✓';
                        }
                    }

                    span {
                        display: block;
                    }

                    .cal-day-badge {
                        position: absolute;
                        width: 16px;
                        padding: 0;
                        height: 16px;
                        display: flex !important;
                        align-items: center;
                        justify-content: center;
                        font-size: 10px;
                        top: -5px;
                        left: -3px;
                        margin: 0;
                    }
                }

                .cal-events {
                    position: absolute;
                    bottom: 1px;
                    left: 50%;
                    transform: translate3d(-50%, 0, 0);
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    margin: 3px 0 0 -1px;

                    .cal-event {
                        width: 6px;
                        height: 6px;
                    }
                }
            }

            .cal-day-number {
                font-size: 12px;
                text-align: center;
                padding: 0;
                margin: 0;
                font-weight: 700;
                opacity: 1;
            }
        }


    }

    .cal-open-day-events {
        background: rgba(55, 48, 75, 0.7);
        box-shadow: none;
        border-radius: 12px;
        color: var(--default-white);
    }

    .close-btn {
        background-color: var(--background-color); //#53486D;
        color: var(--default-white);
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate3d(30px, 100%, 0);
        border-radius: 0 0 12px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        padding: 8px 14px 8px 14px;
        cursor: pointer;
    }
}
